/*
 * @Author: zhang yusen.zhang@mjk24.com
 * @Date: 2022-04-21 09:15:26
 * @LastEditors: zhang yusen.zhang@mjk24.com
 * @LastEditTime: 2022-08-09 14:03:26
 */
import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { Header, Footer } from "@/components";
import { useIntl } from "gatsby-plugin-intl";

const PrivacyPage = (props) => {
  const { data = {} } = props;
  const { banner = {} } = data;

  const { formatMessage } = useIntl();

  const privacy = [
    {
      p: [formatMessage({ id: "page.privacy.p1" })],
    },
    {
      h: formatMessage({ id: "page.privacy.h1" }),
      p: [formatMessage({ id: "page.privacy.p2" })],
    },
    {
      h: formatMessage({ id: "page.privacy.h2" }),
      p: [
        formatMessage({ id: "page.privacy.p3" }),
        formatMessage({ id: "page.privacy.p4" }),
        formatMessage({ id: "page.privacy.p5" }),
      ],
    },
    {
      h: formatMessage({ id: "page.privacy.h3" }),
      p: [
        formatMessage({ id: "page.privacy.p6" }),
        formatMessage({ id: "page.privacy.p7" }),
        formatMessage({ id: "page.privacy.p8" }),
        formatMessage({ id: "page.privacy.p9" }),
      ],
    },
    {
      h: formatMessage({ id: "page.privacy.h4" }),
      p: [
        formatMessage({ id: "page.privacy.p10" }),
        formatMessage({ id: "page.privacy.p11" }),
        formatMessage({ id: "page.privacy.p12" }),
      ],
    },
    {
      h: formatMessage({ id: "page.privacy.h5" }),
      p: [
        formatMessage({ id: "page.privacy.p13" }),
        formatMessage({ id: "page.privacy.p14" }),
        formatMessage({ id: "page.privacy.p15" }),
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-whiter relative flex flex-col">
      <Header {...props} />
      <main className="flex-auto">
        {banner?.publicURL && (
          <div
            className="w-full h-44 md:h-56 xl:h-64 2xl:h-80 bg-no-repeat bg-center bg-cover bg-full"
            style={{
              backgroundImage: `url(${banner.publicURL})`,
            }}
          ></div>
        )}
        <div className="w-full h-auto">
          <div className="max-w-7xl mx-auto py-8 px-4 xl:px-0 md:py-12 lg:py-16 xl:py-24">
            <div className="text-center mb-4">
              <h1 className="text-3xl">{formatMessage({ id: "global.privacy" })}</h1>
            </div>

            <div>
              {privacy.map((item, index) => (
                <Fragment key={index}>
                  {item.h && <h3 className="mt-5 leading-8">{item.h}</h3>}

                  {item.p.map((p, i) => (
                    <p key={i} className="text-gray-500 leading-8">
                      {p}
                    </p>
                  ))}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export const query = graphql`
  query {
    banner: file(relativeDirectory: { eq: "banners" }, name: { eq: "contact" }) {
      id
      publicURL
    }
  }
`;

export default PrivacyPage;
